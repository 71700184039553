.btn-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}

.pos-design .btn-row {
    padding: 24px;
}

.pos-design .btn-row a {
    display: flex;
    align-items: center;
}

.pos-design .btn-row a:last-child {
    margin-right: 0;
}

.pos-design .pos-wrapper {
    --bs-gutter-x: 0;
}

@media (max-width: 575px) {
    .btn-row {
        flex-direction: column;
    }
}

.btn-row .btn {
    font-size: 14px;
    color: #fff;
    margin: 0 10px 0 0;
    padding: 10px;
    border-radius: 4px;
}

@media (max-width: 575px) {
    .btn-row .btn {
        margin: 0 0 15px 0;
        width: 100%;
    }
}

.btn-row .btn:last-child {
    margin-bottom: 0;
}

.pos-categories h5,
.order-list h5,
.pos-categories h6,
.order-list h6 {
    color: #092c4c;
}

.pos-categories h5,
.order-list h5 {
    font-size: 18px;
    font-weight: 700;
}

.btn-info {
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-info:hover {
    background-color: #ff9f43;
    border-color: #ff9f43;
}

.btn-primary {
    background-color: #ff9f43;
    border-color: #ff9f43;
}

.btn-primary:hover {
    background-color: #092c4c;
    border-color: #092c4c;
}

@media (max-width: 991px) {
    .btn-primary {
        margin-top: 10px;
    }
}

@media (max-width: 767px) {
    .btn-primary {
        margin-top: 10px;
    }
}

@media (max-width: 575px) {
    .btn-primary {
        margin-top: 10px;
    }
}

.btn-secondary {
    background-color: #092c4c;
    border-color: #092c4c;
}

.btn-secondary:hover {
    background-color: #ff9f43;
    border-color: #ff9f43;
}

.btn-danger {
    background-color: #f00;
    border-color: #f00;
}

.btn-danger:hover {
    background-color: #092c4c;
    border-color: #092c4c;
}

.btn-success {
    background-color: #28c76f;
    border-color: #28c76f;
}

.btn-success:hover {
    background-color: #092c4c;
    border-color: #092c4c;
}

.owl-carousel .owl-nav {
    margin: 0;
    position: absolute;
    top: -60px;
    right: 0;
}

.owl-carousel .owl-nav button {
    margin-top: 0;
    margin-bottom: 0;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
    position: relative;
    width: 28px;
    height: 28px;
    border-radius: 28px;
}

.owl-carousel .owl-nav button.owl-next i,
.owl-carousel .owl-nav button.owl-prev i {
    color: #ff9f43;
}

.owl-carousel .owl-nav button.owl-next:hover,
.owl-carousel .owl-nav button.owl-prev:hover {
    background-color: #092c4c;
    border-color: #092c4c;
}

.pos-categories {
    background-color: #f8f9fa;
    padding: 24px;
    border-collapse: collapse;
}

.pos-category {
    margin-bottom: 24px;
}

.pos-category .owl-item {
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: center;
    cursor: pointer;
    border: 1px solid #f3f6f9;
    box-shadow: 0px 4px 60px 0px rgba(231, 231, 231, 0.47);
    transition: all 0.5s ease;
    border-radius: 8px;
    margin-left: -0.1px !important;
}

.pos-category .owl-item li {
    padding: 15px 0;
}

.pos-category .owl-item a img {
    transition: all 0.5s ease;
    min-width: 40px;
    width: 40px;
    height: 40px;
    margin: 0 auto;
}

.pos-category .owl-item h6 {
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 5px;
}

.pos-category .owl-item h6 a {
    color: #092c4c;
}

.pos-category .owl-item span {
    color: #5b6670;
}

.pos-category .owl-item:hover {
    border-color: #ff9f43;
    border-radius: 5px;
}

.pos-category .owl-item:hover h6 a {
    color: #ff9f43;
}

.pos-category .owl-item:hover a img {
    transform: scale(1.2);
}

.pos-category .owl-nav button.owl-next {
    margin-left: 5px;
}

.pos-category .owl-nav button.owl-prev {
    margin-right: 5px;
}

.pos-category .owl-nav button.owl-next,
.pos-category .owl-nav button.owl-prev {
    background-color: #fff;
    border: 1px solid #f3f6f9;
}

.pos-category .owl-nav button.owl-next i,
.pos-category .owl-nav button.owl-prev i {
    color: #092c4c;
}

.pos-category .owl-nav button.owl-next:hover,
.pos-category .owl-nav button.owl-prev:hover {
    background-color: #ff9f43;
    border-color: #ff9f43;
}

.pos-category .owl-nav button.owl-next:hover i,
.pos-category .owl-nav button.owl-prev:hover i {
    color: #fff;
}

.pos-products .product-info {
    padding: 15px;
    color: #b8bcc9;
    transition: all 0.5s ease;
}

.pos-products .product-info .img-bg {
    height: 117px;
    background-color: #f3f6f9;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin-bottom: 10px;
    position: relative;
}

.pos-products .product-info .img-bg img {
    transition: all 0.5s ease;
}

.pos-products .product-info .img-bg span {
    transition: all 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 100px;
    background: #28c76f;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    display: none;
}

.pos-products .product-info .img-bg span svg {
    color: #fff;
    position: absolute;
    top: 1px;
    left: 1px;
}

.pos-products .product-info h6 {
    font-size: 15px;
    font-weight: 700;
}

.pos-products .product-info h6.cat-name a {
    color: #b8bcc9;
}

.pos-products .product-info h6.product-name a {
    color: #092c4c;
}

.pos-products .product-info .price {
    margin-top: 17px;
}

.pos-products .product-info .price span {
    color: #7367f0;
}

.pos-products .product-info .price p {
    color: #ff9f43;
}

.pos-products .product-info:hover,
.pos-products .product-info.active {
    border-color: #ff9f43;
}

.pos-products .product-info:hover .img-bg img,
.pos-products .product-info.active .img-bg img {
    transform: scale(1.2);
}

.pos-products .product-info:hover .img-bg span,
.pos-products .product-info.active .img-bg span {
    display: block;
}

.product-wrap {
    height: 34vh;
    overflow: auto;
}

.product-wrap .product-list {
    box-shadow: 0px 4px 60px 0px rgba(231, 231, 231, 0.47);
    margin: 0 0 10px 0;
    padding: 10px;
}

.product-wrap .product-list .product-info {
    width: 60%;
}

.product-wrap .product-list .product-info .img-bg {
    width: 83px;
    height: 83px;
    background-color: #f3f6f9;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin-right: 10px;
}

.product-wrap .product-list .info span {
    background-color: #ff9f43;
    border-radius: 3px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 0 10px;
    min-width: 64px;
}

.product-wrap .product-list .info h6 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
}

.product-wrap .product-list .info h6 a {
    color: #092c4c;
}

.product-wrap .product-list .info p {
    font-size: 15px;
    font-weight: 600;
    color: #5b6670;
}

.product-wrap .product-list .qty-item {
    position: relative;
    margin-right: 7px;
}

.product-wrap .product-list .qty-item input {
    padding: 1px 9px;
    background: #f3f6f9;
    border-radius: 8px;
    height: 28px;
    width: 71px;
    text-align: center;
    font-size: 14px;
}

.product-wrap .product-list .qty-item .dec,
.product-wrap .product-list .qty-item .inc {
    position: absolute;
    top: 50%;
    transform: translateY(-50px);
    color: #092c4c;
    transition: all 0.5s ease;
}

.product-wrap .product-list .qty-item .dec:hover,
.product-wrap .product-list .qty-item .inc:hover {
    color: #ff9f43;
}

.product-wrap .product-list .qty-item .dec {
    left: 9px;
}

.product-wrap .product-list .qty-item .inc {
    right: 9px;
}

.product-wrap .product-list .action a {
    padding: 7px;
    border-radius: 8px;
    border: 1px solid #f3f6f9;
    cursor: pointer;
}

.product-wrap .product-list .action a.edit-icon {
    color: #17a2b8;
}

.product-wrap .product-list .action a.edit-icon:hover {
    background-color: #17a2b8;
    border-color: #17a2b8;
    color: #fff;
}

.product-wrap .product-list .action a.delete-icon {
    color: #f00;
}

.product-wrap .product-list .action a.delete-icon:hover {
    background-color: #f00;
    border-color: #f00;
    color: #fff;
}

aside {
    height: 100%;
    padding: 24px;
    background-color: #fff;
    border-left: 1px solid #f3f6f9;
}

aside.product-order-list h6 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #1b2850;
}

aside.product-order-list .block-section {
    margin: 20px 0 20px 0;
    padding: 0 0 20px 0;
    border-bottom: 1px solid #f3f6f9;
}

aside.product-order-list .head {
    background-color: #f3f6f9;
    border-radius: 8px;
    padding: 10px;
}

aside.product-order-list .head h5 {
    color: #092c4c;
    font-size: 18px;
    font-weight: 700;
}

aside.product-order-list .head span {
    color: #5b6670;
    font-size: 15px;
    font-weight: 500;
}

aside.product-order-list .customer-info .input-block {
    margin-bottom: 10px;
}

aside.product-order-list .customer-info .input-block .btn {
    min-width: 40px;
    min-height: 40px;
    margin: 0 0 0 10px;
    padding: 5px;
}

aside.product-order-list .product-added .head-text {
    margin-bottom: 20px;
}

aside.product-order-list .product-added .head-text .trash svg {
    margin-right: 8px;
}

aside.product-order-list .product-added h6 .count {
    width: 15px;
    height: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    background: #ff9f43;
    border-radius: 100px;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    margin: 0 0 0 7px;
}

aside.product-order-list .selling-info {
    margin-bottom: 24px;
}

aside.product-order-list .selling-info .input-block label {
    margin-bottom: 5px;
}

aside.product-order-list .selling-info .input-block .select2-container--default .select2-selection--single .select2-selection__rendered {
    border-radius: 5px;
    border-color: #1b2850;
}

aside.product-order-list .order-total {
    background-color: #f3f6f9;
    padding: 24px;
    border-radius: 8px;
}

aside.product-order-list .order-total table tr td {
    padding: 0 0 15px 0;
    color: #5b6670;
    font-size: 15px;
    font-weight: 500;
    background: transparent;
}

aside.product-order-list .order-total table tr td.danger {
    color: #f00;
}

aside.product-order-list .order-total table tr:last-child td {
    padding: 20px 0 0 0;
    font-size: 16px;
    font-weight: 600;
}

aside.product-order-list .payment-method .methods .item {
    padding: 0 7px;
}

@media (max-width: 991px) {
    aside.product-order-list .payment-method .methods .item {
        padding-bottom: 15px;
    }
}

aside.product-order-list .payment-method .methods .default-cover {
    transition: all 0.5s ease;
}

aside.product-order-list .payment-method .methods .default-cover a {
    box-shadow: 0px 4px 60px 0px rgba(190, 190, 190, 0.27);
    padding: 10px 15px;
    text-align: center;
    display: block;
    font-size: 15px;
    color: #092c4c;
}

aside.product-order-list .payment-method .methods .default-cover a span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

aside.product-order-list .payment-method .methods .default-cover:hover {
    background-color: #eff2f7;
    border-color: #ff9f43;
}

aside.product-order-list .payment-method .methods .default-cover:hover img {
    filter: invert(75%) sepia(66%) saturate(1955%) hue-rotate(327deg) brightness(103%) contrast(102%);
}

aside.product-order-list .btn-block {
    margin: 20px 0 20px 0;
    padding: 0 0 20px 0;
    border-bottom: 1px solid #f3f6f9;
}

aside.product-order-list .btn-block .btn {
    font-size: 14px;
    font-weight: 700;
    padding: 13px 10px;
}

aside.product-order-list .btn-row {
    padding: 0;
}

aside.product-order-list .btn-row .btn {
    margin-right: 5px;
    border-radius: 4px;
    font-size: 14px;
}

aside.product-order-list .btn-row .btn.btn-info {
    background-color: #7367f0;
    border-color: #7367f0;
}

aside.product-order-list .btn-row .btn.btn-danger {
    background-color: #f00;
    border-color: #f00;
}

aside.product-order-list .btn-row .btn.btn-success {
    background-color: #28c76f;
    border-color: #28c76f;
}

aside.product-order-list .btn-row .btn:hover {
    background-color: #092c4c;
    border-color: #092c4c;
}

#payment-completed .modal-body {
    padding: 40px;
}

#payment-completed .modal-body .icon-head {
    margin-bottom: 10px;
}

#payment-completed .modal-body .icon-head svg {
    color: #28c76f;
}

#payment-completed .modal-body h4 {
    font-size: 20px;
    font-weight: 700;
}

#payment-completed .modal-body .modal-footer .btn:hover {
    color: #fff;
}

#print-receipt {
    color: #5b6670;
}

#print-receipt .modal-dialog {
    width: 424px;
}

#print-receipt .modal-body {
    padding: 30px;
    color: #5b6670;
}

#print-receipt .modal-body h6 {
    font-size: 16px;
}

#print-receipt .modal-body .info h6 {
    margin: 10px 0;
    font-weight: normal;
}

#print-receipt .modal-body .info a {
    color: #5b6670;
}

#print-receipt .modal-body .tax-invoice h6 {
    margin: 10px 0;
    font-weight: 600;
    position: relative;
}

#print-receipt .modal-body .tax-invoice h6:after,
#print-receipt .modal-body .tax-invoice h6:before {
    transform: translateY(-50px);
    content: "";
    border-top: 1px dashed #5b6670;
    width: 35%;
}

#print-receipt .modal-body .tax-invoice h6:before {
    position: absolute;
    top: 50%;
    left: 0;
}

#print-receipt .modal-body .tax-invoice h6:after {
    position: absolute;
    top: 50%;
    right: 0;
}

#print-receipt .modal-body .tax-invoice .invoice-user-name {
    margin-bottom: 10px;
}

#print-receipt .modal-body .tax-invoice span {
    color: #092c4c;
}

#print-receipt .modal-body table thead th {
    color: #092c4c;
    width: auto;
    min-width: auto;
    padding: 5px;
    border-top: 1px dashed #5b6670;
    border-bottom: 1px dashed #5b6670;
}

#print-receipt .modal-body table tbody tr td {
    padding: 5px;
}

#print-receipt .modal-body table tbody tr table {
    border-top: 1px dashed #5b6670;
}

#print-receipt .modal-body table tbody tr table tr:last-child td {
    font-weight: 500;
    font-size: 15px;
    color: #092c4c;
}

#print-receipt .modal-body .invoice-bar {
    border-top: 1px dashed #5b6670;
    padding: 20px 0 0 0;
}

#print-receipt .modal-body .btn {
    width: 200px;
}

.pos-modal .modal-header {
    background-color: #f3f6f9;
}

.pos-modal .modal-header h5 {
    font-size: 18px;
    font-weight: 700;
}

.pos-modal#hold-order h2 {
    font-size: 36px;
    font-weight: 700;
    color: #212529;
    margin-bottom: 10px;
    background-color: #f3f6f9;
    border-radius: 10px;
}

.pos-modal#hold-order .input-block label {
    margin-bottom: 5px;
}

.pos-modal#hold-order p {
    margin-top: 20px;
}

.pos-modal#recents .table tbody tr td {
    padding: 10px 15px;
}

.pos-modal .badge {
    padding: 5px 10px;
    font-size: 15px;
    font-weight: normal;
}

.pos-modal#orders .modal-dialog {
    max-width: 575px;
}

.pos-modal#orders .order-body {
    height: 50vh;
    overflow: auto;
}

.pos-modal#orders .order-body .default-cover {
    border: 1px solid #f3f6f9;
    box-shadow: 0px 4px 60px 0px rgba(231, 231, 231, 0.47);
    border-radius: 8px;
}

.pos-modal#orders .order-body .record {
    font-size: 15px;
}

.pos-modal#orders .order-body .record td {
    padding-bottom: 15px;
}

.pos-modal#orders .order-body .record tr:last-child td {
    padding-bottom: 0;
}

.pos-modal#orders .order-body .record .colon {
    padding: 0 10px;
    color: #092c4c;
}

.pos-modal#orders .order-body .record .text {
    color: #5b6670;
    white-space: nowrap;
}

.pos-modal#orders .order-body p {
    font-size: 15px;
    background-color: #f3f6f9;
    border-radius: 8px;
}

.pos-modal#orders .order-body .search-set .search-input {
    width: 100%;
}

.pos-modal#orders .order-body .search-set .search-input label {
    width: 100%;
}

.pos-modal#orders .order-body .search-set .search-input label input {
    width: 100%;
}

.pos-modal.upload-modal .modal-body .drag-drop {
    position: relative;
    padding: 20px;
    border: 1px dashed #b8bcc9;
}

.pos-modal.upload-modal .modal-body .drag-drop input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.pos-modal.upload-modal .modal-body span {
    font-size: 13px;
    font-weight: 500;
    color: #5b6670;
}

.pos-modal.upload-modal .modal-body p {
    font-size: 16px;
    font-weight: 600;
    color: #092c4c;
    margin-bottom: 0;
}

.pos-modal.upload-modal .modal-body .progress {
    height: 6px;
    border-radius: 5px;
}

.pos-modal.upload-modal .modal-body ul li {
    margin: 0 0 15px 0;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #f3f6f9;
}

.pos-modal.upload-modal .modal-body ul li h6 a {
    font-size: 16px;
    font-weight: 600;
    color: #092c4c;
}

.pos-modal.upload-modal .modal-body ul li h6 a svg {
    color: #28c76f;
}

.pos-modal.upload-modal .modal-body ul li h6:hover a {
    color: #ff9f43;
}

.pos-modal.upload-modal .modal-body ul li:last-child {
    margin-bottom: 0;
}

.pos-modal#upload-message .modal-dialog {
    position: fixed;
    bottom: 0px;
    right: 24px;
    margin: 0px;
    left: auto;
    top: calc(100% - 300px);
    width: 600px;
}

.pos-modal#upload-message .progress {
    height: 6px;
    border-radius: 5px;
}

label span {
    color: #f00;
}

.wordset ul li a {
    width: 38px;
    height: 38px;
    border-radius: 8px;
    border: 1px solid #f3f6f9;
    color: #092c4c;
    display: flex;
    align-items: center;
    justify-content: center;
}

.w-85 {
    width: 85%;
}

.search-order .search-input {
    width: 100%;
}

.search-order .dataTables_filter label {
    width: 100%;
}

.search-set.search-order .search-input input[type=search] {
    width: 100%;
}

.tabs-sets .wordset li {
    margin-right: 8px;
    margin-left: 0;
}

.tabs-sets .wordset li:last-child {
    margin-right: 0;
}