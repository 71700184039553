/* @font-face {
  font-family: 'LEMON-MILK';
  src: url(../public/assets/fonts/LEMONMILK-Regular.otf);
}

@font-face {
  font-family: 'Century-Gothic';
  src: url(../public/assets/fonts/CenturyGothic.ttf);
} */

#root {
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  background-color: #000;
  overflow-x: hidden;
  color: #fff;
}

.listed-link {
  display: inline-block;
  transition: all 0.25s linear;
}

.listed-link:hover {
  transform: translateY(-10px);
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.light-text {
  color: #b6b7bb !important;
}

.fs-14 {
  font-size: 14px !important;
}

small {
  color: #70ec9d;
}

.lh {
  line-height: normal;
}

p {
  color: rgba(184, 187, 189, 0.8);
  font-size: 17px;
  font-weight: 400;
  line-height: 23px;
}

h2 {
  color: #fff;
  font-size: 46px;
  font-weight: 600;
  line-height: 70px;
}

.br-30 {
  border-radius: 30px;
}

/* Header */

.navbar {
  /* height: 85px; */
  position: fixed;
  width: 100%;
  z-index: 111;
  top: 10px;
}

.nav-bg {
  background: linear-gradient(
    90deg,
    rgb(255 255 255 / 0%),
    rgba(255, 255, 255, 0.3)
  );
  padding: 10px 15px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  max-width: 1300px !important;
}

.social-ic {
  display: inline-block;
  transition: all 0.2s ease-in-out;
}

.social-ic:hover {
  transform: translate(0, -5px);
  transition: all 0.2s ease-in-out;
}

.bg-pink {
  /* background: #fff7f272; */
  backdrop-filter: blur(10px);
}

.navbar .nav-link {
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 15px;
  position: relative;
  transition: all 0.2s ease-in-out;
  text-align: center;
}

.navbar .nav-link:hover {
  color: #fff !important;
  transition: all 0.2s ease-in-out;
}

.navbar-nav {
  position: relative;
}

/* .navbar-nav::after {
  content: "";
  background: #2AE8DC;
  height: 2px;
  width: 100%;
  bottom: -5px;
  left: 0;
  position: absolute;
} */

.navbar-collapse.collapsing {
  transition: none !important;
}

/* Banner */
.banner {
  background: url(../public/assets/bannerBg.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  padding-top: 100px;
}

.banner h1 {
  font-size: 60px;
  font-weight: 500;
  line-height: 60px;
  color: #fff;
}

.banner p {
  color: rgb(183, 187, 189, 0.8);
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
}

.primary-btn {
  color: #000;
  border-radius: 10px;
  background: #70ec9d;
  border: 1px solid #70ec9d;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding: 5px 25px;
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.primary-btn:hover {
  background: transparent;
  color: #70ec9d;
  transition: all 0.3s ease-in-out;
}

.primary-btn-outline {
  border-radius: 10px;
  background: black;
  color: #70ec9d;
  border: 1px solid #70ec9d;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding: 5px 25px;
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.primary-btn-outline:hover {
  background: #70ec9d;
  color: #000;
  transition: all 0.3s ease-in-out;
}

.z-1 {
  z-index: 1;
}

.btn-1 {
  background: url(../public/assets/btn-bg1.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  color: black;
  height: 50px;
  padding: 0 40px;
  transition: all 0.2s ease-in-out;
}

.btn-2 {
  background: url(../public/assets/btn-bg.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  height: 50px;
  padding: 0 31px;
  transition: all 0.2s ease-in-out;
}

.btn-3 {
  background: url(../public/assets/btn-bg2.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  height: 50px;
  color: #6fea9c;
  padding: 0 20px;
  transition: all 0.2s ease-in-out;
}

.btn-1:hover,
.btn-2:hover,
.btn-3:hover {
  transform: translate(0, -5px);
  transition: all 0.2s ease-in-out;
}

.btn-1:hover {
  color: #000;
}

.btn-2:hover {
  color: #70ec9d;
}

@media (max-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 950px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar {
    position: fixed;
    z-index: 1111;
    left: 0;
    top: 0px;
    height: auto;
    width: 100%;
  }

  .navbar-collapse {
    position: fixed;
    left: -400px;
    opacity: 0;
    transition: all 0.3s linear;
    display: block !important;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    opacity: 1;
    width: 100%;
    left: 0;
    height: 100vh;
    background: linear-gradient(
      180deg,
      rgba(34, 20, 59, 1),
      rgba(34, 20, 59, 1)
    );
    backdrop-filter: blur(10px);
    z-index: 111;
    left: 0px;
    transition: all 0.3s linear;
    display: block !important;
  }

  .cross-btn {
    font-size: 28px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav {
    align-items: flex-start !important;
  }

  .navbar-brand img {
    height: 30px;
  }

  /* .navbar-nav {
    gap: 20px;
    flex-direction: row;
    margin-top: 25px;
  } */

  .navbar-nav::after {
    content: none;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1180px;
  }
}

h3 {
  color: #fff;
  font-size: 34px;
  font-weight: 500;
  line-height: 70px;
  margin-bottom: 0;
}

h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 70px;
  margin-bottom: 0;
}

.lp {
  position: relative;
}

.lp::before {
  content: '';
  position: absolute;
  left: -30px;
  height: 100%;
  width: 1px;
  background: linear-gradient(
    106deg,
    #ffffff -34.07%,
    rgba(0, 0, 0, 0.23) 102.68%
  );
}

.partner-list h3 {
  font-size: 30px;
  line-height: 70px;
  color: #6fea9c;
  font-weight: 400;
}

.col-20 {
  width: calc(100% / 5);
  padding: 0 12px;
}

.feature-bg {
  background: url(../public/assets/bg1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.metrics-box {
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 30px;
  padding: 30px;
}

.metrics-box h4 {
  font-size: 48px;
  line-height: 50px;
  color: #70ec9d;
  margin-bottom: 10px;
}

.metrics-box p {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: white;
  margin-bottom: 0;
}

.metric-box {
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 30px;
  padding: 20px 30px;
}

.metric-box h4 {
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  margin-bottom: 5px;
}

.metric-box p {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  color: white;
  margin-bottom: 0;
}

.gc-box {
  background: linear-gradient(0deg, rgba(34, 20, 59, 1), rgba(34, 20, 59, 0));
  border-radius: 30px;
  padding: 20px;
}

.gc-box h5 {
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  margin-bottom: 10px;
}

.user-box {
  background: linear-gradient(
    180deg,
    rgba(40, 43, 68, 1),
    rgba(217, 217, 217, 0)
  );
  border-radius: 30px;
  padding: 30px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user-box p {
  font-weight: 300;
  font-size: 17px;
  line-height: 23px;
  color: white;
}

.user-box a {
  color: #2698e1;
}

.user {
  display: flex;
  align-items: center;
  gap: 15px;
}

.user-detail {
  flex: 1;
}

.user-detail p {
  font-weight: 500;
  color: white;
}

.user-img {
  width: 60px;
  height: 60px;
  border-radius: 50px;
}

.user-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50px;
}

.bg-box {
  background-image: url(../public/assets/bg2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  padding: 100px 0;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.bg-box p {
  max-width: 850px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 34px;
  color: white;
}

.os-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.os-card h3 {
  font-size: 20px !important;
  font-weight: 500;
  line-height: 25px !important;
  color: #70ec9d;
}

.os-card p {
  font-weight: 300;
  font-size: 17px;
  line-height: 23px;
}

.work-card {
  background: linear-gradient(180deg, rgba(34, 20, 59, 1), rgba(34, 20, 59, 0));
  border-radius: 30px;
}

.work-box {
  background: linear-gradient(0deg, rgba(34, 20, 59, 1), rgba(34, 20, 59, 0));
  border-radius: 30px;
  padding: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.map-card {
  height: 100%;
  padding: 30px;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.map-card h4 {
  line-height: 60px;
  color: #70ec9d;
}

.map-card ul {
  padding-left: 15px;
}

.map-card ul li {
  list-style-type: disc;
  margin-bottom: 10px;
  color: white;
  font-weight: 300;
}

.com-box {
  background: linear-gradient(
    0deg,
    rgba(51, 68, 85, 1),
    rgba(98, 68, 151, 0.15)
  );
  border-radius: 30px;
  padding: 80px 50px 50px 50px;
}

.bg-3 {
  background-image: url(../public/assets/bg3.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  padding: 50px 0;
}

.bg-3 h4 {
  color: #70ec9d;
}

footer {
  background-image: url(../public/assets/FooterBg.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 260px;
  padding-top: 50px;
}

footer h5 {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
  font-weight: 600;
}

.footer-link {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  font-weight: 300;
  line-height: 20px;
  display: block;
  margin-bottom: 12px;
  transition: all 0.2s ease-in-out;
}

.bt-1 {
  border-top: 1px solid #808080;
}

.footer-link:hover {
  color: #a16ef7 !important;
  transition: all 0.2s ease-in-out;
}

.footer-ic {
  display: inline-block;
  transition: all 0.2s ease-in-out;
}

.footer-ic:hover {
  transform: translate(0, -5px);
  transition: all 0.2s ease-in-out;
}

footer p {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  max-width: 425px;
}

.footer-term {
  color: #50fc15;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
}

.subfooter .container {
  padding: 15px 0;
}

.subfooter p {
  color: #fff;
  font-size: 13px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
}

/* faqs css  */

.faq .accordion-item:first-of-type .accordion-button {
  background-color: transparent !important;
  color: #fff;
  border: 0;
  padding-right: 80px;
}

.faq .accordion-button:not(.collapsed)::after {
  background-image: url(../public/assets/-.svg);
  /* transform: rotate(-180deg); */
}

.faq .accordion-button:not(.collapsed)::before {
  background: transparent;
}

.faq .accordion-button::before {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: absolute;
  content: '';
  right: 3px;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: 2rem;
  transition: transform 0.2s ease-in-out;
  z-index: 0;
}

.faq .accordion-button::before :hover {
  background: transparent;
}

.faq .accordion-button::after {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-left: auto;
  content: '';
  background-image: url(../public/assets/+.svg);
  background-repeat: no-repeat;
  background-size: 2rem;
  transition: transform 0.2s ease-in-out;
  z-index: 9;
  position: absolute;
  right: 20px;
}

.faq .accordion-button::after:hover {
  background-color: red;
}

.faq .accordion-item {
  background-color: transparent;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 10px 0;
  margin-bottom: 10px;
}

.faq .accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.faq .accordion-button {
  border-color: transparent;
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  text-align: left;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: white;
  background-color: transparent;
  padding: 15px 60px 15px 20px !important;
}

.faq .accordion-body {
  font-size: 17px;
  font-weight: 300;
  line-height: 23px;
  color: white;
  max-width: 1100px;
}

.faq .accordion-body a {
  color: #06f4ff;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

/* faq end  */
@media (max-width: 1200px) {
  h3 {
    line-height: 45px;
  }
}
.audit {
  height: 60px;
}

/* .partner-list{
  overflow-x: auto;
  overflow-y: hidden;
}

.scroll-x{
    min-width: 1100px;
} */

.partner-list::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.partner-list::-webkit-scrollbar-track {
  background: #3d3d3d;
}
.partner-list::-webkit-scrollbar-thumb {
  background: #252525;
}

.partner-list .col-2 {
  padding: 0 12px;
}

.scroll-x .col-20 {
  width: calc(100% / 6);
  padding: 0 12px;
}

.w-sm {
  width: 100%;
}

@media (max-width: 1200px) {
  .scroll-x .col-20,
  .partners .col-20 {
    width: calc(100% / 5);
    padding: 0 12px;
  }
}
@media (max-width: 960px) {
  .scroll-x .col-20,
  .partners .col-20 {
    width: calc(100% / 4);
    padding: 0 12px;
  }
}

@media (max-width: 767px) {
  .audit {
    height: 30px;
  }
  .nav-bg {
    border: 0px solid hsla(0, 0%, 100%, 0.3);
    border-radius: 0px;
  }
  h3.col-20 {
    font-size: 18px !important;
  }
  .banner {
    background-size: 170%;
    padding-top: 90px;
    background-position: top right;
  }

  .banner h1 {
    font-size: 34px;
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 36px !important;
  }

  .btn-1,
  .btn-2 {
    font-size: 13px;
    white-space: nowrap;
    padding: 0 20px;
  }

  .banner p {
    font-size: 18px;
    line-height: 28px;
  }

  .partner-list .col-20 {
    width: calc(100% / 3);
    padding: 0 5px;
  }

  /* .partner-list .col-20 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  } */

  .w-sm-100 {
    width: 100%;
    height: auto !important;
  }
  .subfooter .container {
    padding: 15px 15px 0 15px;
  }

  .partner-list .col-20 {
    margin-bottom: 20px;
  }

  h2 {
    font-size: 32px;
    line-height: 42px;
  }

  .metrics-box h4 {
    font-size: 42px;
    line-height: 42px;
  }

  .metrics-box p {
    font-size: 18px;
    line-height: 24px;
  }

  .metrics-box,
  .metric-box {
    border-radius: 20px;
    padding: 25px;
  }

  .feature-bg {
    background: url(../public/assets/fb-sm.png);
    background-position: 100% 100%;
    background-size: 100%;
  }

  .bg-box {
    padding: 30px 20px;
    border-radius: 20px;
  }

  .bg-box p {
    font-size: 16px;
    line-height: 26px;
  }

  .os-card {
    padding: 0 20px;
  }

  .work-box {
    padding: 20px;
  }

  h4 {
    font-size: 22px;
    line-height: 34px !important;
    margin-bottom: 10px;
  }

  .com-box {
    padding: 20px;
  }

  .faq .accordion-body {
    padding-left: 0;
    padding-top: 0;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
  .bg-3 {
    padding: 25px 0;
  }

  .fs-22 {
    font-size: 18px !important;
    line-height: 28px;
    padding: 0 20px;
  }

  .nova-card p {
    font-size: 16px;
  }

  .nova-logo {
    height: 60px;
  }

  h3 {
    font-size: 26px !important;
    line-height: 46px !important;
  }

  .nova-card {
    min-height: 280px;
  }

  .navbar-brand img {
    height: 40px;
  }

  footer {
    padding: 25px 0px;
  }

  .footer-link {
    margin-bottom: 4px;
    font-weight: 300;
  }

  footer h5 {
    margin-bottom: 15px;
  }

  .faq .accordion-button {
    font-size: 16px;
    line-height: 20px;
    padding: 15px 40px 15px 0px !important;
  }

  .faq .accordion-body {
    font-size: 14px;
    line-height: 22px;
  }

  .faq .accordion-button::after {
    width: 1rem;
    height: 1rem;
    background-size: 0.9rem;
    right: 15px;
  }

  .faq .accordion-item {
    padding: 5px 0px 5px 20px;
    border-radius: 10px;
  }
  .scroll-list {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .max-700 {
    min-width: 700px;
  }
  .scroll-list::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .scroll-list::-webkit-scrollbar-track {
    background: #3d3d3d;
  }
  .scroll-list::-webkit-scrollbar-thumb {
    background: #252525;
  }
}
